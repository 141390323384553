import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { logEvent } from "firebase/analytics";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { analytics, auth, db } from "../../app/firebase";
import { ThunkApi } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Label } from "../../types/Label.type";
import { Player, PlayerChanges } from "../../types/Player.type";
import { Standing } from "../../types/Standing.type";
import {
  PlayersFilterOptions,
  selectPlayersFilter,
} from "../filters/filters.feature";
import {
  selectFinishedGames,
  selectGames,
  selectHighScoreGames,
} from "../games/games.feature";
import {
  PlayersSortingOptions,
  selectPlayersSorting,
  selectStandingsSorting,
  StandingsSortingOptions,
} from "../sorting/sorting.feature";
import { selectTournamentRound } from "../tournaments/tournaments.feature";

const playersAdapter = createEntityAdapter<Player>({});

const initialState = playersAdapter.getInitialState({
  status: "idle",
});

export const addPlayer = createAsyncThunk<Player, Player, ThunkApi>(
  "players/addPlayer",
  async (player: Player, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const tid = Object.values(thunkApi.getState().tournaments.entities).find(
      (tournament) => tournament?.active,
    )?.id;
    player = {
      ...player,
      club: player.club === "" ? null : player.club,
      group: player.group === "" ? null : player.group,
      joker: 0,
      available: true,
    };
    const { id } = await addDoc(
      collection(db, `${uid}/${tid}/players`),
      player,
    );
    id && logEvent(analytics, "add", { type: Label.Player });
    return { ...player, id };
  },
);

export const updatePlayer = createAsyncThunk<PlayerChanges, Player, ThunkApi>(
  "players/updatePlayer",
  async (player: Player, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const tid = Object.values(thunkApi.getState().tournaments.entities).find(
      (tournament) => tournament?.active,
    )?.id;
    const changes = {
      prename: player.prename,
      surname: player.surname,
      club: player.club === "" ? null : player.club,
      group: player.group === "" ? null : player.group,
      handycap: player.handycap,
      joker: player.joker,
      available: player.available,
    };
    await updateDoc(doc(db, `${uid}/${tid}/players`, player.id), changes).then(
      () => logEvent(analytics, "update", { type: Label.Player }),
    );
    return {
      id: player.id,
      changes,
    };
  },
);

export const removePlayer = createAsyncThunk<string, string, ThunkApi>(
  "players/removePlayer",
  async (id: string, thunkApi) => {
    const uid = auth.currentUser?.uid;
    const tid = Object.values(thunkApi.getState().tournaments.entities).find(
      (tournament) => tournament?.active,
    )?.id;
    await deleteDoc(doc(db, `${uid}/${tid}/players`, id)).then(() =>
      logEvent(analytics, "remove", { type: Label.Player }),
    );
    return id;
  },
);

export const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    addPlayerFromFirestore(state, action) {
      playersAdapter.addOne(state, action.payload);
    },
    updatePlayerFromFirestore(state, action) {
      playersAdapter.updateOne(state, action.payload);
    },
    removePlayerFromFirestore(state, action) {
      playersAdapter.removeOne(state, action.payload);
    },
    resetPlayers(state) {
      playersAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPlayer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPlayer.fulfilled, (state, action) => {
        playersAdapter.addOne(state, action.payload);
        state.status = "idle";
      })
      .addCase(addPlayer.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updatePlayer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePlayer.fulfilled, (state, action) => {
        playersAdapter.updateOne(state, action.payload);
        state.status = "idle";
      })
      .addCase(updatePlayer.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(removePlayer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removePlayer.fulfilled, (state, action) => {
        playersAdapter.removeOne(state, action.payload);
        state.status = "idle";
      })
      .addCase(removePlayer.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  selectAll: selectPlayers,
  selectById: selectPlayerById,
  selectIds: selectPlayerIds,
} = playersAdapter.getSelectors((state: RootState) => state.players);

export const groups = [
  { id: "A", name: "Group A" },
  { id: "B", name: "Group B" },
];

export const handycaps = Array.from(Array(11).keys()).map((n) => n - 5);

export const drawRandomPlayerIds = (players: string[], maxPlayers: number) => {
  players = structuredClone(players);
  const playersTaken = [];
  while (playersTaken.length < maxPlayers && players.length > 0) {
    const index = Math.floor(Math.random() * players.length);
    const element = players.splice(index, 1)[0];
    playersTaken.push(element);
  }
  return playersTaken;
};

const selectAvailablePlayers = createSelector(selectPlayers, (players) => {
  return players
    .filter((player) => player.available)
    .sort((a, b) => sortByName(a, b));
});

export const selectAvailablePlayerIds = createSelector(
  selectAvailablePlayers,
  (players) => {
    return players.map((player) => player.id);
  },
);

const selectFilteredPlayers = createSelector(
  selectPlayers,
  selectPlayersFilter,
  (players, filter) => {
    if (filter === PlayersFilterOptions.Available) {
      players = players.filter((player) => player.available);
    } else if (filter === PlayersFilterOptions.Joker) {
      players = players.filter((player) => player.joker === 0);
    } else if (filter === PlayersFilterOptions.GroupA) {
      players = players.filter((player) => player.group === "A");
    } else if (filter === PlayersFilterOptions.GroupB) {
      players = players.filter((player) => player.group === "B");
    }
    return players;
  },
);

const sortByName = (a: Player, b: Player) => {
  return (
    a.prename.localeCompare(b.prename) || a.surname.localeCompare(b.surname)
  );
};

const sortByGroup = (a: Player, b: Player) => {
  if (a.group === null && b.group === null) {
    return 0;
  }
  if (a.group === null) {
    return 1;
  }
  if (b.group === null) {
    return -1;
  }
  return a.group.localeCompare(b.group);
};

export const selectFilteredPlayerIds = createSelector(
  selectFilteredPlayers,
  selectPlayersSorting,
  (players, sorting) => {
    if (sorting === PlayersSortingOptions.Name) {
      players.sort((a, b) => sortByName(a, b));
    } else if (sorting === PlayersSortingOptions.Handycap) {
      players = players.sort((a, b) => a.handycap - b.handycap);
    } else if (sorting === PlayersSortingOptions.Group) {
      players.sort((a, b) => sortByGroup(a, b));
    }
    return players.map((player) => player.id);
  },
);

export const selectPlayerIdsByPoints = createSelector(
  selectFinishedGames,
  selectPlayers,
  selectStandingsSorting,
  (games, players, sorting) => {
    const standings: Standing[] = [];

    players.forEach((player) => {
      let points = 0;

      const home = games.filter(
        (game) =>
          game.home.playerA === player.id || game.home.playerB === player.id,
      );
      home.forEach((game) => {
        if (game.home.score > game.away.score) {
          points += game.home.score - game.away.score;
          if (game.round === player.joker) {
            points += game.home.score - game.away.score;
          }
        }
      });

      const away = games.filter(
        (game) =>
          game.away.playerA === player.id || game.away.playerB === player.id,
      );
      away.forEach((game) => {
        if (game.home.score < game.away.score) {
          points += game.away.score - game.home.score;
          if (game.round === player.joker) {
            points += game.away.score - game.home.score;
          }
        }
      });

      standings.push({
        id: player.id,
        points,
        games: home.length + away.length,
      });
    });

    switch (sorting) {
      case StandingsSortingOptions.Games:
        return standings.sort(
          (a: Standing, b: Standing) =>
            b.games - a.games || b.points - a.points,
        );
      default:
        return standings.sort(
          (a: Standing, b: Standing) =>
            b.points - a.points || b.games - a.games,
        );
    }
  },
);

export const selectPlayerIdsByRound = createSelector(
  selectGames,
  selectTournamentRound,
  (games, round) => {
    const players: string[] = [];

    games = games.filter((game) => game.round === round);

    games.forEach((game) => {
      if (!players.includes(game.home.playerA)) {
        players.push(game.home.playerA);
      }
      if (!players.includes(game.home.playerB)) {
        players.push(game.home.playerB);
      }
      if (!players.includes(game.away.playerA)) {
        players.push(game.away.playerA);
      }
      if (!players.includes(game.away.playerB)) {
        players.push(game.away.playerB);
      }
    });

    return players;
  },
);

const selectAvailablePlayersByRound = createSelector(
  selectAvailablePlayers,
  selectPlayerIdsByRound,
  (availablePlayers, unavailablePlayers) => {
    const unavailablePlayersSet = new Set(unavailablePlayers);

    return availablePlayers.filter((player) => {
      return !unavailablePlayersSet.has(player.id);
    });
  },
);

export const selectAvailablePlayerIdsByRound = createSelector(
  selectAvailablePlayersByRound,
  (players) => {
    return players.map((player) => player.id);
  },
);

export const selectAvailablePlayerIdsByRoundForGroupA = createSelector(
  selectAvailablePlayersByRound,
  (players) => {
    return players
      .filter((player) => player.group === "A")
      .map((player) => player.id);
  },
);

export const selectAvailablePlayerIdsByRoundForGroupB = createSelector(
  selectAvailablePlayersByRound,
  (players) => {
    return players
      .filter((player) => player.group === "B")
      .map((player) => player.id);
  },
);

export const selectStartingScore = createSelector(
  [selectPlayers, (state, game) => game],
  (players, game) => {
    const scoreA =
      players.find((player) => player.id === game.playerA)?.handycap ?? 0;
    const scoreB =
      players.find((player) => player.id === game.playerB)?.handycap ?? 0;
    const scoreC =
      players.find((player) => player.id === game.playerC)?.handycap ?? 0;
    const scoreD =
      players.find((player) => player.id === game.playerD)?.handycap ?? 0;

    const dist = Math.abs(scoreA + scoreB - (scoreC + scoreD));
    if (scoreA + scoreB > scoreC + scoreD) {
      return [dist, 0];
    } else {
      return [0, dist];
    }
  },
);

export const selectIfPlayerExists = createSelector(
  [selectPlayers, (state, player) => player],
  (players, player) => {
    return players
      .map((player) => (player.prename + player.surname).toLowerCase())
      .includes(player.toLowerCase());
  },
);

export const selectHighScorePlayers = createSelector(
  selectHighScoreGames,
  (games) => {
    const playerIds: string[] = [];
    games.forEach((game) => {
      if (game.home.score > game.away.score) {
        playerIds.push(game.home.playerA);
        playerIds.push(game.home.playerB);
      } else if (game.home.score < game.away.score) {
        playerIds.push(game.away.playerA);
        playerIds.push(game.away.playerB);
      }
    });
    return playerIds;
  },
);

export const selectClubsFromPlayers = createSelector(
  selectPlayers,
  (players) => {
    return players
      .map((player) => player.club)
      .filter((club) => club !== null)
      .filter((value, index, array) => array.indexOf(value) === index);
  },
);

export const selectRemainingJoker = createSelector(selectPlayers, (players) => {
  return players.filter((player) => player.joker === 0);
});

export const {
  addPlayerFromFirestore,
  updatePlayerFromFirestore,
  removePlayerFromFirestore,
  resetPlayers,
} = playersSlice.actions;

export default playersSlice.reducer;
