import { Box } from "@mui/material";
import { User } from "firebase/auth";

type UserProps = {
  user?: User;
};

export const Logo = ({ user }: UserProps) => {
  const src = user?.email?.includes("@bsc-buederich.de") ? "./logo.svg" : "";
  return (
    <Box
      component="img"
      sx={{
        maxHeight: "3rem",
      }}
      src={src}
    />
  );
};
