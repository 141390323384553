import { MenuItem, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Player } from "./Player";

type PlayerTextFieldProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  values: string[];
  ignoreValues: string[];
};

export const PlayerTextField = ({
  value,
  setValue,
  values,
  ignoreValues,
}: PlayerTextFieldProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      select
      label="Player"
      name="Player"
      value={value}
      onChange={onChange}
      margin="dense"
      sx={{ minWidth: "100%" }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {values.map((value) => (
        <MenuItem
          disabled={ignoreValues.includes(value)}
          key={value}
          value={value}
        >
          <Player id={value} />
        </MenuItem>
      ))}
    </TextField>
  );
};
