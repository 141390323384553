import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const PlayersSortingOptions = {
  Group: "Group",
  Handycap: "Handycap",
  Name: "Name",
};

export const StandingsSortingOptions = {
  Games: "Games",
  Points: "Points",
};

const initialState = {
  players: PlayersSortingOptions.Name,
  standings: StandingsSortingOptions.Points,
};

export const sortingSlice = createSlice({
  name: "sorting",
  initialState,
  reducers: {
    changePlayersSorting(state, action) {
      state.players = action.payload;
    },
    changeStandingsSorting(state, action) {
      state.standings = action.payload;
    },
  },
});

export const selectPlayersSorting = (state: RootState) => state.sorting.players;

export const selectStandingsSorting = (state: RootState) =>
  state.sorting.standings;

export const { changePlayersSorting, changeStandingsSorting } =
  sortingSlice.actions;

export default sortingSlice.reducer;
