import { CircularProgress, Grid } from "@mui/material";

export const Loading = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100vh", padding: 2 }}
    >
      <CircularProgress size="16rem" />
    </Grid>
  );
};
