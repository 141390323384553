import { DisplaySetter } from "../features/display/DisplaySetter";
import { RoundSetter } from "../features/round/RoundSetter";
import { useAppSelector } from "../app/hooks";
import { selectTournament } from "../features/tournaments/tournaments.feature";

export const Footer = () => {
  const tournament = useAppSelector(selectTournament);

  if (tournament) {
    return (
      <>
        <RoundSetter tournament={tournament} />
        <DisplaySetter tournament={tournament} />
      </>
    );
  } else return null;
};
