import { Grid, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Label } from "../types/Label.type";

type EmptyStateProps = {
  icon: ReactNode;
  label: Label;
};

export const EmptyState = ({ icon, label }: EmptyStateProps) => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Stack alignItems="center">
        {icon}
        <Typography variant="h5">
          No {label.toLocaleLowerCase()}s found
        </Typography>
      </Stack>
    </Grid>
  );
};
