import { Chip } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export const HighScoreChip = () => {
  return (
    <Chip
      icon={<StarIcon />}
      label="Highscore"
      color="primary"
      sx={{ ml: 1 }}
    />
  );
};
