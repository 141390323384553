import AbcIcon from "@mui/icons-material/Abc";
import GroupIcon from "@mui/icons-material/Group";
import PinIcon from "@mui/icons-material/Pin";
import SortIcon from "@mui/icons-material/Sort";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppDispatch, useAppSelector, useDropdown } from "../../app/hooks";
import {
  PlayersSortingOptions,
  changePlayersSorting,
  selectPlayersSorting,
} from "../sorting/sorting.feature";

export const PlayersSorting = () => {
  const dispatch = useAppDispatch();
  const players = useAppSelector(selectPlayersSorting);
  const { anchorEl, open, handleOnClick, handleOnClose } = useDropdown();

  return (
    <>
      <Button
        onClick={handleOnClick}
        startIcon={<SortIcon />}
        variant="outlined"
        sx={{ mr: 1 }}
      >
        {players}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleOnClose}>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersSorting(PlayersSortingOptions.Group));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText>{PlayersSortingOptions.Group}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersSorting(PlayersSortingOptions.Handycap));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <PinIcon />
          </ListItemIcon>
          <ListItemText>{PlayersSortingOptions.Handycap}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersSorting(PlayersSortingOptions.Name));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <AbcIcon />
          </ListItemIcon>
          <ListItemText>{PlayersSortingOptions.Name}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
