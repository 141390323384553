import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HistoryIcon from "@mui/icons-material/History";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppDispatch, useAppSelector, useDropdown } from "../../app/hooks";
import {
  changeTournamentsFilter,
  selectTournamentsFilter,
  TournamentsFilterOptions,
} from "../filters/filters.feature";

export const TournamentsFilter = () => {
  const dispatch = useAppDispatch();
  const tournaments = useAppSelector(selectTournamentsFilter);
  const { anchorEl, open, handleOnClick, handleOnClose } = useDropdown();
  return (
    <>
      <Button
        onClick={handleOnClick}
        startIcon={<FilterAltIcon />}
        variant="outlined"
        sx={{ mr: 1 }}
      >
        {tournaments}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleOnClose}>
        <MenuItem
          onClick={() => {
            dispatch(changeTournamentsFilter(TournamentsFilterOptions.All));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <AllInclusiveIcon />
          </ListItemIcon>
          <ListItemText>All</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              changeTournamentsFilter(TournamentsFilterOptions.Upcoming),
            );
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <ScheduleIcon />
          </ListItemIcon>
          <ListItemText>Upcoming</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changeTournamentsFilter(TournamentsFilterOptions.Past));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText>Past</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
