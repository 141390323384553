import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { AddButton } from "../../components/buttons/AddButton";
import { Label } from "../../types/Label.type";
import { selectTournamentId } from "../tournaments/tournaments.feature";
import { AddGameDialog } from "./AddGameDialog";
import { GamesFilter } from "./GamesFilter";
import { GamesTable } from "./GamesTable";

export const Games = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const tournamentId = useAppSelector(selectTournamentId);

  return (
    <Container sx={{ padding: 2 }}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h3">Games</Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <GamesFilter />
          <AddButton
            onClick={() => setOpenDialog(true)}
            label={Label.Game}
            disabled={!tournamentId}
          />
          {openDialog && (
            <AddGameDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <GamesTable />
        </Grid>
      </Grid>
    </Container>
  );
};
