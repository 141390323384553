import { Route, Routes } from "react-router-dom";
import { useGamesStore, usePlayerStore } from "../app/hooks";
import { Display } from "../features/display/Display";
import { Games } from "../features/games/Games";
import { Players } from "../features/players/Players";
import { Standings } from "../features/standings/Standings";
import { Tournaments } from "../features/tournaments/Tournaments";

export const Main = () => {
  usePlayerStore();
  useGamesStore();

  return (
    <Routes>
      <Route path="/" element={<Tournaments />} />
      <Route path="/players" element={<Players />} />
      <Route path="/games" element={<Games />} />
      <Route path="/standings" element={<Standings />} />
      <Route path="/tournaments" element={<Tournaments />} />
      <Route path="/display" element={<Display />} />
    </Routes>
  );
};
