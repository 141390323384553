import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Chip } from "@mui/material";

type ChipProps = {
  available: boolean;
};

export const AvailableChip = ({ available }: ChipProps) => {
  return (
    <Chip
      icon={available ? <EventAvailableIcon /> : <EventBusyIcon />}
      label={available ? "Available" : "Unavailable"}
      sx={{ mr: 1 }}
    />
  );
};
