import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { UpdateButton } from "../../components/buttons/UpdateButton";
import { NumberInputSelect } from "../../components/inputs/NumberInputSelect";
import { TextInput } from "../../components/inputs/TextInput";
import { TextInputSelect } from "../../components/inputs/TextInputSelect";
import { Label } from "../../types/Label.type";
import { Player } from "../../types/Player.type";
import {
  groups,
  handycaps,
  selectIfPlayerExists,
  updatePlayer,
} from "./players.feature";
import { InputGroup } from "../../components/inputs/InputGroup";

type UpdatePlayerDialogProps = {
  player: Player;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const UpdatePlayerDialog = ({
  player,
  openDialog,
  setOpenDialog,
}: UpdatePlayerDialogProps) => {
  const dispatch = useAppDispatch();
  const [prename, setPrename] = useState(player.prename);
  const [surname, setSurname] = useState(player.surname);
  const [club, setClub] = useState(player.club ?? "");
  const [group, setGroup] = useState(player.group ?? "");
  const [handycap, setHandycap] = useState(player.handycap);
  const [disabled, setDisabled] = useState(
    prename.length === 0 || surname.length === 0,
  );

  useEffect(() => {
    setDisabled(prename.length === 0 || surname.length === 0);
  }, [prename, surname]);

  const clubs = useAppSelector((state) => state.api.clubs);
  const playerExists = useAppSelector(
    (state) =>
      player.prename + player.surname !== prename + surname &&
      selectIfPlayerExists(state, prename + surname),
  );

  const resetForm = () => {
    setPrename("");
    setSurname("");
    setClub("");
    setGroup("");
    setHandycap(0);
    setDisabled(true);
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();
    dispatch(
      updatePlayer({
        ...player,
        prename,
        surname,
        club,
        group,
        handycap,
      } as Player),
    );
    setOpenDialog(false);
    resetForm();
  };

  const getName = () => {
    return `${player.prename} ${player.surname}`;
  };

  return (
    <Dialog
      maxWidth="lg"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>Update player</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update player <i>{getName()}</i>
        </DialogContentText>
        <Box sx={{ mt: 2 }}>
          <InputGroup>
            <TextInput label="Prename" value={prename} setValue={setPrename} />
            <TextInput label="Surname" value={surname} setValue={setSurname} />
          </InputGroup>
          <Divider sx={{ my: 1 }} />
          <InputGroup>
            <TextInputSelect
              label="Club"
              value={club ?? null}
              setValue={setClub}
              values={clubs}
            />
            <TextInputSelect
              label="Group"
              value={group ?? null}
              setValue={setGroup}
              values={groups}
            />
            <NumberInputSelect
              label="Handycap"
              value={handycap}
              setValue={setHandycap}
              values={handycaps}
            />
          </InputGroup>
          {playerExists && (
            <Alert severity="error" sx={{ m: 1 }}>
              Player already exists
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <UpdateButton
          onClick={handleOnClick}
          label={Label.Player}
          disabled={disabled || playerExists}
        />
      </DialogActions>
    </Dialog>
  );
};
