import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { EmptyState } from "../../components/EmptyState";
import { Label } from "../../types/Label.type";
import { GamesTableRow } from "./GamesTableRow";
import { selectFilteredGameIds } from "./games.feature";

export const GamesTable = () => {
  const gameIds = useAppSelector(selectFilteredGameIds);

  if (gameIds.length > 0) {
    return (
      <TableContainer>
        <Table size="small">
          <caption>{gameIds.length} game(s) found</caption>
          <TableHead>
            <TableRow
              sx={{
                "& th": { fontWeight: 600 },
              }}
            >
              <TableCell>Status</TableCell>
              <TableCell>Round</TableCell>
              <TableCell align="right">Home</TableCell>
              <TableCell align="center">Score</TableCell>
              <TableCell>Away</TableCell>
              <TableCell sx={{ width: 128 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameIds.map((id) => (
              <GamesTableRow key={id} id={id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <EmptyState
        icon={<ScoreboardIcon fontSize="large" />}
        label={Label.Game}
      />
    );
  }
};
