import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { Label } from "../../types/Label.type";

type UpdateButtonProps = {
  onClick: (e?: any) => void;
  label: Label;
  disabled?: boolean;
};

export const UpdateButton = ({
  onClick,
  label,
  disabled = false,
}: UpdateButtonProps) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<EditIcon />}
      variant="outlined"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
