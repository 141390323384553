import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button } from "@mui/material";

type NextButtonProps = {
  disabled: boolean;
  onClick: (e?: any) => void;
};

export const NextButton = ({ disabled, onClick }: NextButtonProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      startIcon={<NavigateNextIcon />}
      variant="contained"
    >
      Next
    </Button>
  );
};
