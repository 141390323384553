import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DoneIcon from "@mui/icons-material/Done";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppDispatch, useAppSelector, useDropdown } from "../../app/hooks";
import {
  changeGamesFilter,
  GamesFilterOptions,
  selectGamesFilter,
} from "../filters/filters.feature";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export const GamesFilter = () => {
  const dispatch = useAppDispatch();
  const games = useAppSelector(selectGamesFilter);
  const { anchorEl, open, handleOnClick, handleOnClose } = useDropdown();

  return (
    <>
      <Button
        onClick={handleOnClick}
        startIcon={<FilterAltIcon />}
        variant="outlined"
        sx={{ mr: 1 }}
      >
        {games}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleOnClose}>
        <MenuItem
          onClick={() => {
            dispatch(changeGamesFilter(GamesFilterOptions.All));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <AllInclusiveIcon />
          </ListItemIcon>
          <ListItemText>All</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changeGamesFilter(GamesFilterOptions.Upcoming));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <ScheduleIcon />
          </ListItemIcon>
          <ListItemText>Upcoming</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changeGamesFilter(GamesFilterOptions.Finished));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <DoneIcon />
          </ListItemIcon>
          <ListItemText>Finished</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changeGamesFilter(GamesFilterOptions.Round));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <AutorenewIcon />
          </ListItemIcon>
          <ListItemText>Round</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
