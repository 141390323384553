import { Typography, TypographyTypeMap } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { HighScoreChip } from "../../components/chips/HighScoreChip";
import { JokerChip } from "../../components/chips/JokerChip";
import { selectPlayerById } from "./players.feature";

type PlayerProps = {
  id: string;
  highScore?: boolean;
  round?: number;
  variant?: TypographyTypeMap["props"]["variant"];
};

export const Player = ({
  id,
  highScore,
  round,
  variant = "inherit",
}: PlayerProps) => {
  const player = useAppSelector((state) => selectPlayerById(state, id));

  return (
    <>
      {player ? (
        <Typography variant={variant}>
          {player.prename} {player.surname} [{player.handycap > 0 && "+"}
          {player.handycap}]{highScore && <HighScoreChip />}
          {player.joker === round && <JokerChip color="primary" />}
        </Typography>
      ) : null}
    </>
  );
};
