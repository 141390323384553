import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { Label } from "../../types/Label.type";

type AddButtonProps = {
  onClick: (e?: any) => void;
  label: Label;
  disabled?: boolean;
};

export const AddButton = ({
  onClick,
  label,
  disabled = false,
}: AddButtonProps) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<AddIcon />}
      variant="outlined"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
