import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GroupIcon from "@mui/icons-material/Group";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppDispatch, useAppSelector, useDropdown } from "../../app/hooks";
import {
  PlayersFilterOptions,
  changePlayersFilter,
  selectPlayersFilter,
} from "../filters/filters.feature";

export const PlayersFilter = () => {
  const dispatch = useAppDispatch();
  const players = useAppSelector(selectPlayersFilter);
  const { anchorEl, open, handleOnClick, handleOnClose } = useDropdown();
  return (
    <>
      <Button
        onClick={handleOnClick}
        startIcon={<FilterAltIcon />}
        variant="outlined"
        sx={{ mr: 1 }}
      >
        {players}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleOnClose}>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersFilter(PlayersFilterOptions.All));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <AllInclusiveIcon />
          </ListItemIcon>
          <ListItemText>All</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersFilter(PlayersFilterOptions.Available));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <EventAvailableIcon />
          </ListItemIcon>
          <ListItemText>Available</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersFilter(PlayersFilterOptions.Joker));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <AutoAwesomeIcon />
          </ListItemIcon>
          <ListItemText>Joker</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersFilter(PlayersFilterOptions.GroupA));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText>Group A</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changePlayersFilter(PlayersFilterOptions.GroupB));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText>Group B</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
