import { Container, Grid, Typography } from "@mui/material";
import { StandingsTable } from "./StandingsTable";
import { StandingsSorting } from "./StandingsSorting";

export const Standings = () => {
  return (
    <Container sx={{ padding: 2 }}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h3">Standings</Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <StandingsSorting />
        </Grid>
        <Grid item xs={12}>
          <StandingsTable variant="inherit" />
        </Grid>
      </Grid>
    </Container>
  );
};
