import { MenuItem, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

type IdName = {
  id: string;
  name: string;
};

type TextInputSelectProps = {
  label: string;
  value: string | null;
  setValue: Dispatch<SetStateAction<string>>;
  values: IdName[];
};

export const TextInputSelect = ({
  label,
  value,
  setValue,
  values,
}: TextInputSelectProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      select
      label={label}
      name={label}
      value={value}
      onChange={onChange}
      margin="dense"
      sx={{ minWidth: 96 }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {values.map((value) => (
        <MenuItem key={value.id} value={value.id}>
          {value.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
