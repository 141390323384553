import { Header } from "./Header";
import { Login } from "./Login";

export const Unauthentificated = () => {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <main className="App-body">
        <Login />
      </main>
    </div>
  );
};
