import { Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { AddButton } from "../../components/buttons/AddButton";
import { Label } from "../../types/Label.type";
import { AddTournamentDialog } from "./AddTournamentDialog";
import { TournamentsFilter } from "./TournamentsFilter";
import { TournamentsTable } from "./TournamentsTable";

export const Tournaments = () => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Container sx={{ padding: 2 }}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h3">Tournaments</Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <TournamentsFilter />
          <AddButton
            onClick={() => setOpenDialog(true)}
            label={Label.Tournament}
          />
          {openDialog && (
            <AddTournamentDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TournamentsTable />
        </Grid>
      </Grid>
    </Container>
  );
};
