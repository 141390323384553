import GroupsIcon from "@mui/icons-material/Groups";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { EmptyState } from "../../components/EmptyState";
import { Label } from "../../types/Label.type";
import { PlayersTableRow } from "./PlayerTableRow";
import { selectFilteredPlayerIds } from "./players.feature";

export const PlayersTable = () => {
  const playerIds = useAppSelector(selectFilteredPlayerIds);

  if (playerIds.length > 0) {
    return (
      <TableContainer>
        <Table size="small">
          <caption>{playerIds.length} player(s) found</caption>
          <TableHead>
            <TableRow
              sx={{
                "& th": { fontWeight: 600 },
              }}
            >
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Club</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Handycap</TableCell>
              <TableCell sx={{ width: 160 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playerIds.map((id) => (
              <PlayersTableRow key={id} id={id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <EmptyState icon={<GroupsIcon fontSize="large" />} label={Label.Player} />
    );
  }
};
