import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Chip } from "@mui/material";

type ChipProps = {
  color?: "default" | "primary";
};

export const JokerChip = ({ color = "default" }: ChipProps) => {
  return (
    <Chip
      icon={<AutoAwesomeIcon />}
      label="Joker"
      sx={{ ml: 1 }}
      color={color}
    />
  );
};
