import AutorenewIcon from "@mui/icons-material/Autorenew";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectRounds } from "../games/games.feature";
import { updateTournament } from "../tournaments/tournaments.feature";
import { TournamentProps } from "../../types/Tournament.type";

export const RoundSetter = ({ tournament }: TournamentProps) => {
  const dispatch = useAppDispatch();
  const rounds = useAppSelector(selectRounds);

  return (
    <SpeedDial
      icon={<AutorenewIcon />}
      ariaLabel="Change display"
      sx={{
        position: "fixed",
        left: 16,
        bottom: 16,
      }}
    >
      {rounds
        .slice()
        .reverse()
        .map((round) => (
          <SpeedDialAction
            key={round}
            icon={round}
            tooltipTitle={`R${round}`}
            tooltipOpen
            tooltipPlacement="right"
            onClick={() => {
              dispatch(
                updateTournament({
                  ...tournament,
                  round,
                }),
              );
            }}
          />
        ))}
    </SpeedDial>
  );
};
