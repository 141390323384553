import { Box, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { DeleteIconButton } from "../../components/buttons/DeleteIconButton";
import { EditIconButton } from "../../components/buttons/EditIconButton";
import { FinishedChip } from "../../components/chips/FinishedChip";
import { DeleteDialog } from "../../components/dialogs/DeleteDialog";
import { Label } from "../../types/Label.type";
import { IdProps } from "../../types/Props.type";
import { Player } from "../players/Player";
import { UpdateGameDialog } from "./UpdateGameDialog";
import { removeGame, selectGameById } from "./games.feature";

export const GamesTableRow = ({ id }: IdProps) => {
  const game = useAppSelector((state) => selectGameById(state, id));
  const [showIcons, setShowIcons] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  return (
    <>
      {game ? (
        <TableRow
          key={id}
          sx={{
            "& td": { lineHeight: 2 },
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
          hover
        >
          <TableCell>
            <FinishedChip finished={game.finished} />
          </TableCell>
          <TableCell>{game.round}</TableCell>
          <TableCell align="right">
            <Player id={game.home.playerA} />
            <Player id={game.home.playerB} />
          </TableCell>
          <TableCell align="center">
            {game.home.score} : {game.away.score}
          </TableCell>
          <TableCell>
            <Player id={game.away.playerA} />
            <Player id={game.away.playerB} />
          </TableCell>
          <TableCell>
            {showIcons && (
              <Box display="inline">
                <EditIconButton
                  onClick={setOpenUpdateDialog}
                  title="Update game"
                />
                {openUpdateDialog && (
                  <UpdateGameDialog
                    game={game}
                    openDialog={openUpdateDialog}
                    setOpenDialog={setOpenUpdateDialog}
                  />
                )}
                <DeleteIconButton
                  onClick={setOpenDeleteDialog}
                  title="Delete game"
                />
                {openDeleteDialog && (
                  <DeleteDialog
                    id={game.id}
                    openDialog={openDeleteDialog}
                    setOpenDialog={setOpenDeleteDialog}
                    action={removeGame}
                    label={Label.Game}
                  />
                )}
              </Box>
            )}
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
