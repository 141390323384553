import { Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { AddButton } from "../../components/buttons/AddButton";
import { Label } from "../../types/Label.type";
import { selectTournamentId } from "../tournaments/tournaments.feature";
import { AddPlayerDialog } from "./AddPlayerDialog";
import { PlayersFilter } from "./PlayersFilter";
import { PlayersSorting } from "./PlayersSorting";
import { PlayersTable } from "./PlayersTable";

export const Players = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const tournamentId = useAppSelector(selectTournamentId);

  return (
    <Container sx={{ padding: 2 }}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h3">Players</Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <PlayersSorting />
          <PlayersFilter />
          <AddButton
            onClick={() => setOpenDialog(true)}
            label={Label.Player}
            disabled={!tournamentId}
          />
          {openDialog && (
            <AddPlayerDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <PlayersTable />
        </Grid>
      </Grid>
    </Container>
  );
};
