import HistoryIcon from "@mui/icons-material/History";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Chip } from "@mui/material";

type ChipProps = { date: string };

export const DateChip = ({ date }: ChipProps) => {
  return (
    <Chip
      icon={
        date >= new Date().toISOString().split("T")[0] ? (
          <ScheduleIcon />
        ) : (
          <HistoryIcon />
        )
      }
      label={
        date >= new Date().toISOString().split("T")[0] ? "Upcoming" : "Past"
      }
      sx={{ mr: 1 }}
    />
  );
};
