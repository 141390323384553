import { TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { isDate } from "../../app/dates";

type DateInputProps = {
  label: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  setDisabled?: Dispatch<SetStateAction<boolean>>;
};

export const DateInput = ({
  label,
  value,
  setValue,
  setDisabled = () => {},
}: DateInputProps) => {
  const [error, setError] = useState(!isDate(value));

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setError(!isDate(e.target.value));
    setDisabled(!isDate(e.target.value));
  };

  return (
    <TextField
      required
      label={label}
      name={label}
      value={value}
      onChange={onChange}
      helperText={error && "Required"}
      margin="dense"
    />
  );
};
