import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyTypeMap,
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { Player } from "../players/Player";
import {
  selectHighScorePlayers,
  selectPlayerIdsByPoints,
} from "../players/players.feature";
import { Label } from "../../types/Label.type";
import { EmptyState } from "../../components/EmptyState";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

type StandingsTableProps = {
  variant: TypographyTypeMap["props"]["variant"];
};

export const StandingsTable = ({ variant }: StandingsTableProps) => {
  const standings = useAppSelector(selectPlayerIdsByPoints);
  const highScorePlayers = useAppSelector(selectHighScorePlayers);

  if (standings.length > 0) {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 600 }} variant={variant}>
                  Player
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 600 }} variant={variant}>
                  Games
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 600 }} variant={variant}>
                  Points
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standings.map((standing, index) => (
              <TableRow key={standing.id}>
                <TableCell>
                  <Typography sx={{ fontWeight: 600 }} variant={variant}>
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Player
                    id={standing.id}
                    highScore={highScorePlayers.includes(standing.id)}
                    variant={variant}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant={variant}>{standing.games}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={variant}>{standing.points}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <EmptyState
        icon={<FormatListNumberedIcon fontSize="large" />}
        label={Label.Standing}
      />
    );
  }
};
