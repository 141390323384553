import { Card, Divider, Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { IdProps } from "../../types/Props.type";
import { DisplayPlayer } from "../display/DisplayPlayer";
import { selectGameById } from "../games/games.feature";

export const RoundTableRow = ({ id }: IdProps) => {
  const game = useAppSelector((state) => selectGameById(state, id));

  return (
    <>
      {game ? (
        <Card
          elevation={4}
          sx={{
            p: 2,
          }}
        >
          <Grid container>
            <Grid container alignItems="flex-start" direction="column" xs={10}>
              <Grid item>
                <DisplayPlayer
                  id={game.home.playerA}
                  round={game.round}
                  variant="h3"
                />
              </Grid>
              <Grid item>
                <DisplayPlayer
                  id={game.home.playerB}
                  round={game.round}
                  variant="h3"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" xs={2}>
              <Typography variant="h1">{game.home.score}</Typography>
            </Grid>
            <Divider
              sx={{
                mt: 1,
                borderColor: "#999",
                width: "100%",
              }}
            />
            <Grid container alignItems="flex-start" direction="column" xs={10}>
              <Grid item>
                <DisplayPlayer
                  id={game.away.playerA}
                  round={game.round}
                  variant="h3"
                />
              </Grid>
              <Grid item>
                <DisplayPlayer
                  id={game.away.playerB}
                  round={game.round}
                  variant="h3"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" xs={2}>
              <Typography variant="h1">{game.away.score}</Typography>
            </Grid>
          </Grid>
        </Card>
      ) : null}
    </>
  );
};
