import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import { getDateString } from "../../app/dates";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditIconButton } from "../../components/buttons/EditIconButton";
import { ActiveChip } from "../../components/chips/ActiveChip";
import { DateChip } from "../../components/chips/DateChip";
import { IdProps } from "../../types/Props.type";
import { resetGames } from "../games/games.feature";
import { resetPlayers } from "../players/players.feature";
import { UpdateTournamentDialog } from "./UpdateTournamentDialog";
import {
  loadTournament,
  selectTournamentById,
  selectTournamentId,
  unloadTournament,
} from "./tournaments.feature";
import DownloadIcon from "@mui/icons-material/Download";

export const TournamentTableRow = ({ id }: IdProps) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => selectTournamentById(state, id));
  const tournamentId = useAppSelector(selectTournamentId);
  const [showIcons, setShowIcons] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  return (
    <>
      {tournament && tournamentId ? (
        <TableRow
          key={id}
          sx={{
            "& td": { lineHeight: 2 },
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
          hover
        >
          <TableCell>
            <DateChip date={tournament.date} />
          </TableCell>
          <TableCell>
            {tournament.name}
            {tournament.active && <ActiveChip />}
          </TableCell>
          <TableCell>{tournament.round}</TableCell>
          <TableCell>{getDateString(tournament.date)}</TableCell>
          <TableCell>
            {showIcons && (
              <Box display="inline">
                <Tooltip title="Load tournament">
                  <span>
                    <IconButton
                      onClick={() => {
                        dispatch(resetPlayers());
                        dispatch(resetGames());
                        dispatch(unloadTournament(tournamentId));
                        dispatch(loadTournament(tournament.id));
                      }}
                      disabled={tournament.active}
                      sx={{ ml: 1, p: 0 }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <EditIconButton
                  onClick={setOpenUpdateDialog}
                  title="Update tournament"
                />
                {openUpdateDialog && (
                  <UpdateTournamentDialog
                    tournament={tournament}
                    openDialog={openUpdateDialog}
                    setOpenDialog={setOpenUpdateDialog}
                  />
                )}
              </Box>
            )}
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
