import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { UpdateButton } from "../../components/buttons/UpdateButton";
import { NumberInput } from "../../components/inputs/NumberInput";
import { RoundInput } from "../../components/inputs/RoundInput";
import { Game } from "../../types/Game.type";
import { Label } from "../../types/Label.type";
import { Player } from "../players/Player";
import { updateGame } from "./games.feature";

type UpdateGameDialogProps = {
  game: Game;
  openDialog: boolean;
  setOpenDialog: any;
};

export const UpdateGameDialog = ({
  game,
  openDialog,
  setOpenDialog,
}: UpdateGameDialogProps) => {
  const dispatch = useAppDispatch();

  const [round, setRound] = useState(game.round);
  const [homeScore, setHomeScore] = useState(game.home.score ?? 0);
  const [awayScore, setAwayScore] = useState(game.away.score ?? 0);
  const [disabled, setDisabled] = useState(
    round <= 0 || homeScore < 0 || awayScore < 0,
  );

  useEffect(() => {
    setDisabled(round <= 0 || homeScore < 0 || awayScore < 0);
  }, [round, homeScore, awayScore]);

  const resetForm = () => {
    setRound(1);
    setHomeScore(0);
    setAwayScore(0);
    setDisabled(true);
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();
    dispatch(
      updateGame({
        id: game.id,
        home: {
          playerA: game.home.playerA,
          playerB: game.home.playerB,
          score: homeScore,
        },
        away: {
          playerA: game.away.playerA,
          playerB: game.away.playerB,
          score: awayScore,
        },
        round,
        finished:
          game.home.score !== homeScore || game.away.score !== awayScore,
      }),
    );
    setOpenDialog(false);
    resetForm();
  };

  return (
    <Dialog
      maxWidth="xl"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>Update game</DialogTitle>
      <DialogContent>
        <DialogContentText>Set round or score of game</DialogContentText>
        <Box sx={{ mt: 2 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={2}>
              <RoundInput label="Round" value={round} setValue={setRound} />
            </Grid>
            <Grid sx={{ textAlign: "right" }} item xs>
              <Player id={game.home.playerA} variant="body2" />
              <Player id={game.home.playerB} variant="body2" />
            </Grid>
            <Grid item xs={2}>
              <NumberInput
                label="Home"
                value={homeScore}
                setValue={setHomeScore}
              />
            </Grid>
            <Grid item xs={2}>
              <NumberInput
                label="Away"
                value={awayScore}
                setValue={setAwayScore}
              />
            </Grid>
            <Grid item xs>
              <Player id={game.away.playerA} variant="body2" />
              <Player id={game.away.playerB} variant="body2" />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <UpdateButton
          onClick={handleOnClick}
          label={Label.Game}
          disabled={disabled}
        />
      </DialogActions>
    </Dialog>
  );
};
