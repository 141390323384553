import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddButton } from "../../components/buttons/AddButton";
import { NumberInputSelect } from "../../components/inputs/NumberInputSelect";
import { TextInput } from "../../components/inputs/TextInput";
import { TextInputSelect } from "../../components/inputs/TextInputSelect";
import { Label } from "../../types/Label.type";
import { Player } from "../../types/Player.type";
import { PlayerAutocomplete } from "./PlayerAutocomplete";
import {
  addPlayer,
  groups,
  handycaps,
  selectIfPlayerExists,
} from "./players.feature";
import { InputGroup } from "../../components/inputs/InputGroup";

type AddPlayerDialogProps = {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const AddPlayerDialog = ({
  openDialog,
  setOpenDialog,
}: AddPlayerDialogProps) => {
  const dispatch = useAppDispatch();
  const [prename, setPrename] = useState("");
  const [surname, setSurname] = useState("");
  const [club, setClub] = useState("");
  const [group, setGroup] = useState("");
  const [handycap, setHandycap] = useState(0);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(prename.length === 0 || surname.length === 0);
  }, [prename, surname]);

  const clubs = useAppSelector((state) => state.api.clubs);
  const playerExists = useAppSelector((state) =>
    selectIfPlayerExists(state, prename + surname),
  );

  const onAutocompleteChange = (e: any, value: any) => {
    if (value) {
      setPrename(value.prename);
      setSurname(value.surname);
      setClub(value.club);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setPrename("");
    setSurname("");
    setClub("");
    setGroup("");
    setHandycap(0);
    setDisabled(true);
  };

  const handleOnClick = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(addPlayer({ prename, surname, club, group, handycap } as Player));
    setOpenDialog(false);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>Add player</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select player from the list or add player by name
        </DialogContentText>
        <Box sx={{ mt: 2 }}>
          <PlayerAutocomplete onChange={onAutocompleteChange} />
          <Divider sx={{ my: 1 }} />
          <InputGroup>
            <TextInput label="Prename" value={prename} setValue={setPrename} />
            <TextInput label="Surname" value={surname} setValue={setSurname} />
          </InputGroup>
          <Divider sx={{ my: 1 }} />
          <InputGroup>
            <TextInputSelect
              label="Club"
              value={club ?? null}
              setValue={setClub}
              values={clubs}
            />
            <TextInputSelect
              label="Group"
              value={group ?? null}
              setValue={setGroup}
              values={groups}
            />
            <NumberInputSelect
              label="Handycap"
              value={handycap}
              setValue={setHandycap}
              values={handycaps}
            />
          </InputGroup>
          {playerExists && (
            <Alert severity="error" sx={{ m: 1 }}>
              Player already exists
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <AddButton
          onClick={handleOnClick}
          label={Label.Player}
          disabled={disabled || playerExists}
        />
      </DialogActions>
    </Dialog>
  );
};
