import { configureStore } from "@reduxjs/toolkit";
import apiReducer from "../features/api/api.feature";
import filtersReducer from "../features/filters/filters.feature";
import gamesReducer from "../features/games/games.feature";
import playersReducer from "../features/players/players.feature";
import sortingReducer from "../features/sorting/sorting.feature";
import tournamentsReducer from "../features/tournaments/tournaments.feature";

export const store = configureStore({
  reducer: {
    api: apiReducer,
    players: playersReducer,
    games: gamesReducer,
    tournaments: tournamentsReducer,
    filters: filtersReducer,
    sorting: sortingReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
