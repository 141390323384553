import { Autocomplete, Box, TextField } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { Label } from "../../types/Label.type";

type PlayerAutocompleteProps = {
  onChange: (e: any, value: any) => void;
};

export const PlayerAutocomplete = ({ onChange }: PlayerAutocompleteProps) => {
  const players = useAppSelector((state) => state.api.players);

  if (players.length > 0) {
    return (
      <Autocomplete
        options={players}
        groupBy={(option) => option.prename.charAt(0)}
        getOptionLabel={(option) => option.prename + " " + option.surname}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.prename} {option.surname}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            name={Label.Player}
            {...params}
            label="Select player"
            margin="dense"
          />
        )}
        onChange={onChange}
      />
    );
  } else {
    return null;
  }
};
