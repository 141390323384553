import { createTheme, responsiveFontSizes } from "@mui/material";
import { User } from "firebase/auth";
import clubs from "../features/clubs/clubs.json";

export const theme = (user: User | null) => {
  let main = "#0085BE"; // Default color
  if (user?.email) {
    const key = user.email.match(/@.*/)?.at(0);
    main = clubs[key as keyof typeof clubs].color;
  }

  return responsiveFontSizes(
    createTheme({
      palette: {
        primary: { main },
        info: { main },
      },
      typography: {
        button: {
          textTransform: "none",
        },
        fontSize: 16,
      },
    }),
  );
};
