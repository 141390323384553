import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type IconButtonProps = {
  onClick: Dispatch<SetStateAction<boolean>>;
  title?: string;
};

export const EditIconButton = ({
  onClick,
  title = "Update",
}: IconButtonProps) => {
  return (
    <Tooltip title={title}>
      <IconButton onClick={() => onClick(true)} sx={{ p: 0, ml: 1 }}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};
