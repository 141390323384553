import { MenuItem, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

type NumberInputSelectProps = {
  label: string;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  values: number[];
};

export const NumberInputSelect = ({
  label,
  value,
  setValue,
  values,
}: NumberInputSelectProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(e.target.value));
  };

  return (
    <TextField
      select
      label={label}
      name={label}
      value={value}
      onChange={onChange}
      margin="dense"
      sx={{ width: 96 }}
    >
      {values.map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </TextField>
  );
};
