import { Card, Grid, Typography } from "@mui/material";

type TileProps = {
  title: any;
  icon?: any;
  value?: any;
};

export const Tile = ({ title, icon, value }: TileProps) => {
  return (
    <Card
      elevation={4}
      sx={{
        p: 2,
      }}
    >
      <Grid container alignItems="center">
        <Typography variant="h4" sx={{ mr: "auto" }}>
          {title}
        </Typography>
        {icon}
      </Grid>
      <Grid>
        <Typography variant="h1">{value}</Typography>
      </Grid>
    </Card>
  );
};
