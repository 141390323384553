import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button } from "@mui/material";

type PrevButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const PrevButton = ({ disabled, onClick }: PrevButtonProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      startIcon={<NavigateBeforeIcon />}
      sx={{ mr: 1 }}
    >
      Prev
    </Button>
  );
};
