import { Grid } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectGameIdsByRound } from "../games/games.feature";
import { RoundTableRow } from "./RoundTableRow";

export const RoundTable = () => {
  const games = useAppSelector(selectGameIdsByRound);

  return (
    <Grid container spacing={2} sx={{ mt: 0 }}>
      {games.map((game) => (
        <Grid item key={game} xs={12} lg={6}>
          <RoundTableRow id={game} />
        </Grid>
      ))}
    </Grid>
  );
};
