import { SpeedDial, SpeedDialAction } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import {
  DisplayOptions,
  updateTournament,
} from "../tournaments/tournaments.feature";
import MonitorIcon from "@mui/icons-material/Monitor";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import { TournamentProps } from "../../types/Tournament.type";

export const DisplaySetter = ({ tournament }: TournamentProps) => {
  const dispatch = useAppDispatch();

  const actions = [
    { icon: <ScoreboardIcon />, name: "Games", display: DisplayOptions.Games },
    {
      icon: <FormatListNumberedIcon />,
      name: "Standings",
      display: DisplayOptions.Standings,
    },
    {
      icon: <EmojiEventsIcon />,
      name: "Tournament",
      display: DisplayOptions.Tournament,
    },
  ];

  return (
    <SpeedDial
      icon={<MonitorIcon />}
      ariaLabel="Change display"
      sx={{
        position: "fixed",
        right: 16,
        bottom: 16,
      }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          tooltipPlacement="left"
          onClick={() => {
            dispatch(
              updateTournament({
                ...tournament,
                display: action.display,
              }),
            );
          }}
        />
      ))}
    </SpeedDial>
  );
};
