import { Stack, TypographyTypeMap } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { ClubLogo } from "../../components/logos/ClubLogo";
import { Player } from "../players/Player";
import { selectPlayerById } from "../players/players.feature";

type DisplayPlayerProps = {
  id: string;
  round?: number;
  variant?: TypographyTypeMap["props"]["variant"];
};

export const DisplayPlayer = ({
  id,
  round,
  variant = "inherit",
}: DisplayPlayerProps) => {
  const player = useAppSelector((state) => selectPlayerById(state, id));

  return (
    <>
      {player ? (
        <Stack direction="row" alignItems="center">
          <ClubLogo club={player.club} />
          <Player id={id} round={round} variant={variant} />
        </Stack>
      ) : null}
    </>
  );
};
