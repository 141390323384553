import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Tooltip } from "@mui/material";
import { User } from "firebase/auth";
import { useState } from "react";
import { Sidebar } from "./Sidebar";

type NavigationProps = {
  user: User;
};

export const Navigation = ({ user }: NavigationProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
      <Tooltip title="Open menu">
        <IconButton color="inherit" onClick={() => setOpen(true)}>
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Sidebar user={user} open={open} setOpen={setOpen} />
    </Box>
  );
};
