import { Box } from "@mui/material";

type ClubProps = {
  club?: string | null;
};

export const ClubLogo = ({ club }: ClubProps) => {
  return (
    <Box
      component={club ? "img" : "div"}
      sx={{
        height: "3.6rem",
        width: "3.6rem",
        mr: 1,
      }}
      src={`./clubs/${club}.png`}
    />
  );
};
