import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { Label } from "../../types/Label.type";

type DeleteDialogProps = {
  id: string;
  openDialog: boolean;
  setOpenDialog: any;
  action: any;
  label: Label;
};

export const DeleteDialog = ({
  id,
  openDialog,
  setOpenDialog,
  action,
  label,
}: DeleteDialogProps) => {
  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    dispatch(action(id));
  };

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Delete {label.toLocaleLowerCase()}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The following action <b>can not</b> be undone
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenDialog(false)}
          startIcon={<ArrowBackIcon />}
          variant="outlined"
        >
          Go back
        </Button>
        <Button
          onClick={handleOnClick}
          startIcon={<DeleteIcon />}
          variant="outlined"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
