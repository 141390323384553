import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { IconButton, Tooltip } from "@mui/material";

type AvailableIconButtonProps = {
  available: boolean;
  onClick: any;
};

export const AvailableIconButton = ({
  available,
  onClick,
}: AvailableIconButtonProps) => {
  return (
    <Tooltip title={available ? "Set unavailable" : "Set available"}>
      <IconButton onClick={onClick} sx={{ ml: 1, p: 0 }}>
        {available ? <EventBusyIcon /> : <EventAvailableIcon />}
      </IconButton>
    </Tooltip>
  );
};
