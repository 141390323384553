import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { getDateString, today } from "../../app/dates";
import { useAppDispatch, useAppSelector, useStepper } from "../../app/hooks";
import { NextButton } from "../../components/buttons/NextButton";
import { PrevButton } from "../../components/buttons/PrevButton";
import { DateInput } from "../../components/inputs/DateInput";
import { TextInput } from "../../components/inputs/TextInput";
import { Tournament } from "../../types/Tournament.type";
import {
  addTournament,
  selectTournamentId,
  unloadTournament,
} from "./tournaments.feature";

type AddTournamentDialogProps = {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const AddTournamentDialog = ({
  openDialog,
  setOpenDialog,
}: AddTournamentDialogProps) => {
  const dispatch = useAppDispatch();
  const tournamentId = useAppSelector(selectTournamentId);

  const [name, setName] = useState("");
  const [date, setDate] = useState(today);
  const [disabled, setDisabled] = useState(true);
  const { activeStep, handleNext, handlePrev } = useStepper();

  const handlConfirm = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    tournamentId && dispatch(unloadTournament(tournamentId));
    dispatch(addTournament({ name, date } as Tournament));
    setOpenDialog(false);
  };

  const steps = [
    {
      label: "Name",
      element: (
        <TextInput
          label="Name"
          value={name}
          setValue={setName}
          setDisabled={setDisabled}
        />
      ),
    },
    {
      label: "Date",
      element: (
        <DateInput
          label="Date"
          value={date}
          setValue={setDate}
          setDisabled={setDisabled}
        />
      ),
    },
    {
      label: "Confirm",
      element: (
        <Typography>
          Add tournament <i>{name}</i> on <i>{getDateString(date)}</i>
        </Typography>
      ),
    },
  ];

  const isFinalStep = () => activeStep === steps.length - 1;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>Add tournament</DialogTitle>
      <DialogContent>
        <DialogContentText>Add tournament by name and date</DialogContentText>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {step.element}
                <Box m={1}>
                  <PrevButton
                    disabled={activeStep === 0}
                    onClick={handlePrev}
                  />
                  <NextButton
                    disabled={disabled}
                    onClick={isFinalStep() ? handlConfirm : handleNext}
                  />
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};
