import { User } from "firebase/auth";
import {
  useApiStore,
  useRouterAnalytics,
  useTournamentStore,
} from "../app/hooks";
import { Header } from "./Header";
import { Main } from "./Main";
import { useLocation } from "react-router-dom";
import { Footer } from "./Footer";

type UserProps = {
  user: User;
};

export const Authenticated = ({ user }: UserProps) => {
  useTournamentStore();
  useApiStore();
  useRouterAnalytics();
  const location = useLocation();

  return (
    <div className="App">
      <header className="App-header">
        <Header user={user} />
      </header>
      <main className="App-body">
        <Main />
      </main>
      <footer className="App-footer">
        {location.pathname !== "/display" && <Footer />}
      </footer>
    </div>
  );
};
