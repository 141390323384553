import { TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

type RoundInputProps = {
  label: string;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
};

export const RoundInput = ({ label, value, setValue }: RoundInputProps) => {
  const [error, setError] = useState(value <= 0);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(e.target.value));
    setError(parseInt(e.target.value) <= 0);
  };

  return (
    <TextField
      label={label}
      name={label}
      value={value}
      type="number"
      onChange={onChange}
      helperText={error && "Required"}
      sx={{ width: 96 }}
    />
  );
};
