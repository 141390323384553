import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { IconButton, Tooltip } from "@mui/material";

type JokerIconButtonProps = {
  joker: number;
  onClick: any;
};

export const JokerIconButton = ({ joker, onClick }: JokerIconButtonProps) => {
  return (
    <Tooltip title={joker > 0 ? "Reset joker" : "Set joker"}>
      <IconButton onClick={onClick} sx={{ ml: 1, p: 0 }}>
        <AutoAwesomeIcon />
      </IconButton>
    </Tooltip>
  );
};
