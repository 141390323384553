import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { isDate } from "../../app/dates";
import { useAppDispatch } from "../../app/hooks";
import { UpdateButton } from "../../components/buttons/UpdateButton";
import { DateInput } from "../../components/inputs/DateInput";
import { TextInput } from "../../components/inputs/TextInput";
import { Label } from "../../types/Label.type";
import { Tournament } from "../../types/Tournament.type";
import { updateTournament } from "./tournaments.feature";
import { InputGroup } from "../../components/inputs/InputGroup";

type UpdateTournamentDialogProps = {
  tournament: Tournament;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const UpdateTournamentDialog = ({
  tournament,
  openDialog,
  setOpenDialog,
}: UpdateTournamentDialogProps) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(tournament.name);
  const [date, setDate] = useState(tournament.date);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(name.length === 0 || !isDate(date));
  }, [name, date]);

  const resetForm = () => {
    setName("");
    setDate("");
    setDisabled(true);
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();
    dispatch(updateTournament({ ...tournament, name, date }));
    setOpenDialog(false);
    resetForm();
  };

  return (
    <Dialog
      maxWidth="lg"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>Update tournament</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update tournament <i>{tournament.name}</i>
        </DialogContentText>
        <InputGroup>
          <TextInput label="Name" value={name} setValue={setName} />
          <DateInput label="Date" value={date} setValue={setDate} />
        </InputGroup>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <UpdateButton
          onClick={handleOnClick}
          label={Label.Tournament}
          disabled={disabled}
        />
      </DialogActions>
    </Dialog>
  );
};
