import { logEvent } from "firebase/analytics";
import { User } from "firebase/auth";
import { collection, onSnapshot, query } from "firebase/firestore";
import { MouseEvent, useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchClubs, fetchPlayers } from "../features/api/api.feature";
import {
  addGameFromFirestore,
  removeGameFromFirestore,
  updateGameFromFirestore,
} from "../features/games/games.feature";
import {
  addPlayerFromFirestore,
  removePlayerFromFirestore,
  updatePlayerFromFirestore,
} from "../features/players/players.feature";
import {
  addTournamentFromFirestore,
  removeTournamentFromFirestore,
  selectTournamentId,
  updateTournamentFromFirestore,
} from "../features/tournaments/tournaments.feature";
import { analytics, auth, db } from "./firebase";
import type { AppDispatch, RootState } from "./store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ThunkApi = { state: RootState };

export const useDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, open, handleOnClick, handleOnClose };
};

export const useAuth = () => {
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        logEvent(analytics, "login");
      } else {
        setUser(null);
      }
    });
  }, []);

  return { user };
};

export const useStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return { activeStep, handleNext, handlePrev };
};

export const useTournamentStore = () => {
  const dispatch = useAppDispatch();
  const uid = auth.currentUser?.uid;
  const q = query(collection(db, `${uid}`));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        dispatch(
          addTournamentFromFirestore({
            ...change.doc.data(),
            id: change.doc.id,
          }),
        );
      }
      if (change.type === "modified") {
        dispatch(
          updateTournamentFromFirestore({
            id: change.doc.id,
            changes: change.doc.data(),
          }),
        );
      }
      if (change.type === "removed") {
        dispatch(removeTournamentFromFirestore(change.doc.id));
      }
    });
  });
  return () => unsubscribe();
};

export const useApiStore = () => {
  const dispatch = useAppDispatch();
  dispatch(fetchClubs());
  dispatch(fetchPlayers());
};

export const usePlayerStore = () => {
  const dispatch = useAppDispatch();
  const uid = auth.currentUser?.uid;
  const tid = useAppSelector(selectTournamentId);
  const q = query(collection(db, `${uid}/${tid}/players`));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        dispatch(
          addPlayerFromFirestore({ ...change.doc.data(), id: change.doc.id }),
        );
      }
      if (change.type === "modified") {
        dispatch(
          updatePlayerFromFirestore({
            id: change.doc.id,
            changes: change.doc.data(),
          }),
        );
      }
      if (change.type === "removed") {
        dispatch(removePlayerFromFirestore(change.doc.id));
      }
    });
  });
  return () => unsubscribe();
};

export const useGamesStore = () => {
  const dispatch = useAppDispatch();
  const uid = auth.currentUser?.uid;
  const tid = useAppSelector(selectTournamentId);
  const q = query(collection(db, `${uid}/${tid}/games`));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        dispatch(
          addGameFromFirestore({ ...change.doc.data(), id: change.doc.id }),
        );
      }
      if (change.type === "modified") {
        dispatch(
          updateGameFromFirestore({
            id: change.doc.id,
            changes: change.doc.data(),
          }),
        );
      }
      if (change.type === "removed") {
        dispatch(removeGameFromFirestore(change.doc.id));
      }
    });
  });
  return () => unsubscribe();
};

export const useRouterAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: location.pathname,
      firebase_screen_class: "react-router",
    });
  }, [location]);
};
