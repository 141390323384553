import DoneIcon from "@mui/icons-material/Done";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Chip } from "@mui/material";

type ChipProps = {
  finished: boolean;
};

export const FinishedChip = ({ finished }: ChipProps) => {
  return (
    <Chip
      icon={finished ? <DoneIcon /> : <ScheduleIcon />}
      label={finished ? "Finished" : "Upcoming"}
    />
  );
};
