import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

type IconButtonProps = {
  onClick: Dispatch<SetStateAction<boolean>>;
  title?: string;
};

export const DeleteIconButton = ({
  onClick,
  title = "Delete",
}: IconButtonProps) => {
  return (
    <Tooltip title={title}>
      <IconButton onClick={() => onClick(true)} sx={{ p: 0, ml: 1 }}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};
