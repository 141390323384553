import SortIcon from "@mui/icons-material/Sort";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppDispatch, useAppSelector, useDropdown } from "../../app/hooks";
import {
  selectStandingsSorting,
  changeStandingsSorting,
  StandingsSortingOptions,
} from "../sorting/sorting.feature";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

export const StandingsSorting = () => {
  const dispatch = useAppDispatch();
  const standings = useAppSelector(selectStandingsSorting);
  const { anchorEl, open, handleOnClick, handleOnClose } = useDropdown();

  return (
    <>
      <Button
        onClick={handleOnClick}
        startIcon={<SortIcon />}
        variant="outlined"
        sx={{ mr: 1 }}
      >
        {standings}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleOnClose}>
        <MenuItem
          onClick={() => {
            dispatch(changeStandingsSorting(StandingsSortingOptions.Games));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <ScoreboardIcon />
          </ListItemIcon>
          <ListItemText>{StandingsSortingOptions.Games}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(changeStandingsSorting(StandingsSortingOptions.Points));
            handleOnClose();
          }}
        >
          <ListItemIcon>
            <FormatListNumberedIcon />
          </ListItemIcon>
          <ListItemText>{StandingsSortingOptions.Points}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
