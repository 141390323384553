import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DoneIcon from "@mui/icons-material/Done";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import GroupsIcon from "@mui/icons-material/Groups";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import { Grid } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { Label } from "../../types/Label.type";
import {
  selectFinishedGames,
  selectGameIds,
  selectRoundsPlayed as selectPlayedRounds,
  selectUpcomingGames,
} from "../games/games.feature";
import {
  selectAvailablePlayerIds,
  selectClubsFromPlayers,
  selectPlayerIds,
  selectRemainingJoker,
} from "../players/players.feature";
import { Tile } from "./Tile";
import { selectTournamentName } from "./tournaments.feature";

export const TournamentOverview = () => {
  const name = useAppSelector(selectTournamentName);
  const players = useAppSelector(selectPlayerIds);
  const availablePlayers = useAppSelector(selectAvailablePlayerIds);
  const remainingJoker = useAppSelector(selectRemainingJoker);
  const games = useAppSelector(selectGameIds);
  const finishedGames = useAppSelector(selectFinishedGames);
  const upcomingGames = useAppSelector(selectUpcomingGames);
  const clubs = useAppSelector(selectClubsFromPlayers);
  const playedRounds = useAppSelector(selectPlayedRounds);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Tile
          icon={<EmojiEventsIcon fontSize="large" />}
          title={Label.Tournament}
          value={name}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<GroupsIcon fontSize="large" />}
          title="Players"
          value={players.length}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<ScoreboardIcon fontSize="large" />}
          title="Games"
          value={games.length}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<EventAvailableIcon fontSize="large" />}
          title="Available players"
          value={availablePlayers.length}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<DoneIcon fontSize="large" />}
          title="Finished games"
          value={finishedGames.length}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<AutoAwesomeIcon fontSize="large" />}
          title="Remaining joker"
          value={remainingJoker.length}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<ScheduleIcon fontSize="large" />}
          title="Upcoming games"
          value={upcomingGames.length}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<MilitaryTechIcon fontSize="large" />}
          title="Clubs"
          value={clubs.length}
        />
      </Grid>
      <Grid item xs={6}>
        <Tile
          icon={<AutorenewIcon fontSize="large" />}
          title="Played rounds"
          value={playedRounds}
        />
      </Grid>
    </Grid>
  );
};
